<template>
  <v-container fluid>
    <div class="mx-auto" style="max-width: 600px;">
      <v-sheet outlined class="pa-2">
        <v-sheet outlined>
          <NotifikasiList v-show="NotifikasiList == false" :NotifikasiList="NotifikasiList"/>
        </v-sheet>
      </v-sheet>
    </div>

    <v-fab-transition>
      <v-btn
        :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey darken-1'"
        v-scroll="onScroll"
        v-show="fab"
        fab
        small
        dark
        fixed
        bottom
        right
        @click="toTop"
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>

<script>
  import NotifikasiList from '@/views/Notifikasi/NotifikasiList.vue';
  export default {
    components: {
      NotifikasiList
    },
    props: ['Notifikasi'],

    data: () => {
      return {
        fab: false,
        NotifikasiList: false,
      }
    },

    mounted(){
      
    },

    created(){

    },

    computed: {
      
    },

    watch: {
      
    },

    methods: {
      onScroll (e) {
        if (typeof window === 'undefined') return
        const top = window.pageYOffset ||   e.target.scrollTop || 0
        this.fab = top > 20
      },
      toTop () {
        this.$vuetify.goTo(0)
      },
    },
  }
</script>