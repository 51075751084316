<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title class="title">Notifikasi <span>(0)</span></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-filter-variant</v-icon>
      </v-btn>
    </v-toolbar>

    <v-divider></v-divider>

    <v-menu>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          class="ma-2 text-capitalize"
          v-bind="attrs"
          v-on="on"
        >
          <span v-show="HariIni">Hari Ini</span>
          <span v-show="BelumTerbaca">Belum Terbaca</span>
          <span v-show="SemuaPesan">Semua</span>
          <v-icon class="mr-n2">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group mandatory>
          <v-list-item link @click.native="TampilkanNotifHariIni">
            <v-list-item-title>Hari Ini</v-list-item-title>
          </v-list-item>
          <v-list-item link @click.native="TampilkanNotifBelumTerbaca">
            <v-list-item-title>Belum Terbaca</v-list-item-title>
          </v-list-item>
          <v-list-item link @click.native="TampilkanSemuaNotif">
            <v-list-item-title>Semua</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

    <v-divider></v-divider>

    <v-list class="overflow-hidden">
      <v-list-item-group
        v-model="NotifikasiList"
        mandatory
      >
        <template v-for="(item, index) in items">
          <v-list-item three-line :key="item.title" @click="DialogDetailNotifikasi = true">
            <v-list-item-avatar :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'">
              <v-icon v-text="item.image"></v-icon>
              <!-- <v-img></v-img> -->
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span class="body-1" v-text="item.title"></span>
              </v-list-item-title>

              <v-list-item-subtitle class="text--primary" v-text="item.name"></v-list-item-subtitle>
              <v-list-item-subtitle v-text="item.caption"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-list-item-action-text v-text="item.date"></v-list-item-action-text>
              <v-list-item-action-text v-text="item.time"></v-list-item-action-text>
              <!-- <v-list-item-action-text class="text--primary">Baru</v-list-item-action-text> -->
            </v-list-item-action>
          </v-list-item>

          <v-divider
            v-if="index < items.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>

    <v-dialog
      v-model="DialogDetailNotifikasi"
      max-width="380"
    >
      <v-card rounded="lg">
        <v-card-title>
          <span  class="text-h5 mb-1">Title</span>
          <v-spacer></v-spacer>
          <v-btn icon class="mr-n2" @click="DialogDetailNotifikasi = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle class="body-1">Name</v-card-subtitle>
        <v-card-subtitle class="body-1">yyyy-mm-dd</v-card-subtitle>
        <v-card-subtitle class="body-1 mt-n6">00.00</v-card-subtitle>
        <v-card-text>
          <span class="body-1">Caption</span>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props: ['Notifikasi'],

    data: () => {
      return {
        HariIni: true,
        BelumTerbaca: false,
        SemuaPesan: false,
        NotifikasiList: [],
        DialogDetailNotifikasi: false,
        items: [
          {
            date: 'yyyy-mm-dd',
            time: '00.00',
            image: 'mdi-account',
            title: 'Title 1',
            name: 'Name',
            caption: 'Caption',
          },
          {
            date: 'yyyy-mm-dd',
            time: '00.00',
            image: 'mdi-account',
            title: 'Title 2',
            name: 'Name',
            caption: 'Caption',
          },
          {
            date: 'yyyy-mm-dd',
            time: '00.00',
            image: 'mdi-account',
            title: 'Title 3',
            name: 'Name',
            caption: 'Caption',
          },
          {
            date: 'yyyy-mm-dd',
            time: '00.00',
            image: 'mdi-account',
            title: 'Title 4',
            name: 'Subtitle',
            caption: 'Caption',
          },
          {
            date: 'yyyy-mm-dd',
            time: '00.00',
            image: 'mdi-account',
            title: 'Title 5',
            name: 'Subtitle',
            caption: 'Caption',
          },
          {
            date: 'yyyy-mm-dd',
            time: '00.00',
            image: 'mdi-account',
            title: 'Title 6',
            name: 'Subtitle',
            caption: 'Caption',
          },
          {
            date: 'yyyy-mm-dd',
            time: '00.00',
            image: 'mdi-account',
            title: 'Title 7',
            name: 'Subtitle',
            caption: 'Caption',
          },
          {
            date: 'yyyy-mm-dd',
            time: '00.00',
            image: 'mdi-account',
            title: 'Title 8',
            name: 'Subtitle',
            caption: 'Caption',
          },
          {
            date: 'yyyy-mm-dd',
            time: '00.00',
            image: 'mdi-account',
            title: 'Title 9',
            name: 'Subtitle',
            caption: 'Caption',
          },
          {
            date: 'yyyy-mm-dd',
            time: '00.00',
            image: 'mdi-account',
            title: 'Title 10',
            name: 'Subtitle',
            caption: 'Caption',
          },
        ],
      }
    },

    mounted(){
      
    },

    created(){

    },

    computed: {
      
    },

    watch: {
      ShowNotifikasi(value){
        this.notifikasi = value
      },

      notifikasi(value){
        this.$parent.ShowNotifikasi = value
      }
    },

    methods: {
      TampilkanNotifHariIni(){
        this.HariIni = true
        this.BelumTerbaca = false
        this.SemuaPesan = false
        this.TextGetNotif = "notifikasiusertoday"
        this.loading = true
        // this.getnotif()
      },
      TampilkanNotifBelumTerbaca(){
        this.HariIni = false
        this.BelumTerbaca = true
        this.SemuaPesan = false
        this.TextGetNotif = "notifikasibelumterbaca"
        this.loading = true
        // this.getnotif()
      },
      TampilkanSemuaNotif(){
        this.HariIni = false
        this.BelumTerbaca = false
        this.SemuaPesan = true
        this.TextGetNotif = "notifikasiuser"
        this.loading = true
        // this.getnotif()
      },
    },
  }
</script>